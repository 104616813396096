let data = {
    a: ["a", "ɑ", "α", "а", "⍺", "𝐚", "𝑎", "𝒂", "𝒶", "𝓪", "𝔞", "𝕒", "𝖆", "𝖺", "𝗮", "𝘢", "𝙖", "𝚊", "𝛂", "𝛼", "𝜶", "𝝰", "𝞪", "ａ"],
    b: ["b", "Ƅ", "Ь", "Ꮟ", "ᑲ", "ᖯ", "𝐛", "𝑏", "𝒃", "𝒷", "𝓫", "𝔟", "𝕓", "𝖇", "𝖻", "𝗯", "𝘣", "𝙗", "𝚋"],
    c: ["c", "ϲ", "с", "ᴄ", "ⅽ", "ⲥ", "ꮯ", "𐐽", "𝐜", "𝑐", "𝒄", "𝒸", "𝓬", "𝔠", "𝕔", "𝖈", "𝖼", "𝗰", "𝘤", "𝙘", "𝚌", "ｃ"],
    d: ["d", "ԁ", "Ꮷ", "ᑯ", "ⅆ", "ⅾ", "ꓒ", "𝐝", "𝑑", "𝒅", "𝒹", "𝓭", "𝔡", "𝕕", "𝖉", "𝖽", "𝗱", "𝘥", "𝙙", "𝚍"],
    e: ["e", "е", "ҽ", "℮", "ℯ", "ⅇ", "ꬲ", "𝐞", "𝑒", "𝒆", "𝓮", "𝔢", "𝕖", "𝖊", "𝖾", "𝗲", "𝘦", "𝙚", "𝚎", "ｅ"],
    f: ["f", "ſ", "ք", "ẝ", "ꞙ", "ꬵ", "𝐟", "𝑓", "𝒇", "𝒻", "𝓯", "𝔣", "𝕗", "𝖋", "𝖿", "𝗳", "𝘧", "𝙛", "𝚏"],
    g: ["g", "ƍ", "ɡ", "ց", "ᶃ", "ℊ", "𝐠", "𝑔", "𝒈", "𝓰", "𝔤", "𝕘", "𝖌", "𝗀", "𝗴", "𝘨", "𝙜", "𝚐", "ｇ"],
    h: ["h", "һ", "հ", "Ꮒ", "ℎ", "𝐡", "𝒉", "𝒽", "𝓱", "𝔥", "𝕙", "𝖍", "𝗁", "𝗵", "𝘩", "𝙝", "𝚑", "ｈ"],
    i: ["i", "ı", "ɩ", "ɪ", "˛", "ͺ", "ι", "і", "ӏ", "Ꭵ", "ι", "ℹ", "ⅈ", "ⅰ", "⍳", "ꙇ", "ꭵ", "𑣃", "𝐢", "𝑖", "𝒊", "𝒾", "𝓲", "𝔦", "𝕚", "𝖎", "𝗂", "𝗶", "𝘪", "𝙞", "𝚒", "𝚤", "𝛊", "𝜄", "𝜾", "𝝸", "𝞲", "ｉ"],
    j: ["j", "ϳ", "ј", "ⅉ", "𝐣", "𝑗", "𝒋", "𝒿", "𝓳", "𝔧", "𝕛", "𝖏", "𝗃", "𝗷", "𝘫", "𝙟", "𝚓", "ｊ"],
    k: ["k", "𝐤", "𝑘", "𝒌", "𝓀", "𝓴", "𝔨", "𝕜", "𝖐", "𝗄", "𝗸", "𝘬", "𝙠", "𝚔"],
    l: ["1", "I", "l", "|", "Ɩ", "ǀ", "Ι", "І", "Ӏ", "׀", "ו", "ן", "ا", "١", "۱", "ߊ", "ᛁ", "ℐ", "ℑ", "ℓ", "Ⅰ", "ⅼ", "∣", "⏽", "Ⲓ", "ⵏ", "ꓲ", "𐊊", "𐌉", "𐌠", "𖼨", "𝐈", "𝐥", "𝐼", "𝑙", "𝑰", "𝒍", "𝓁", "𝓘", "𝓵", "𝔩", "𝕀", "𝕝", "𝕴", "𝖑", "𝖨", "𝗅", "𝗜", "𝗹", "𝘐", "𝘭", "𝙄", "𝙡", "𝙸", "𝚕", "𝚰", "𝛪", "𝜤", "𝝞", "𝞘", "𝟏", "𝟙", "𝟣", "𝟭", "𝟷", "𞣇", "𞸀", "𞺀", "🯱", "ﺍ", "ﺎ", "Ｉ", "ｌ", "￨"],
    m: ["m", "rn", "ⅿ", "𑜀", "𑣣", "𝐦", "𝑚", "𝒎", "𝓂", "𝓶", "𝔪", "𝕞", "𝖒", "𝗆", "𝗺", "𝘮", "𝙢", "𝚖"],
    n: ["n", "ո", "ռ", "𝐧", "𝑛", "𝒏", "𝓃", "𝓷", "𝔫", "𝕟", "𝖓", "𝗇", "𝗻", "𝘯", "𝙣", "𝚗"],
    o: ["o", "ο", "σ", "о", "օ", "ס", "ه", "٥", "ھ", "ہ", "ە", "۵", "०", "੦", "૦", "௦", "౦", "೦", "ഠ", "൦", "๐", "໐", "ဝ", "၀", "ჿ", "ᴏ", "ᴑ", "ℴ", "ⲟ", "ꬽ", "𐐬", "𐓪", "𑣈", "𑣗", "𝐨", "𝑜", "𝒐", "𝓸", "𝔬", "𝕠", "𝖔", "𝗈", "𝗼", "𝘰", "𝙤", "𝚘", "𝛐", "𝛔", "𝜊", "𝜎", "𝝄", "𝝈", "𝝾"],
    p: ["p", "ρ", "ϱ", "р", "⍴", "ⲣ", "𝐩", "𝑝", "𝒑", "𝓅", "𝓹", "𝔭", "𝕡", "𝖕", "𝗉", "𝗽", "𝘱", "𝙥", "𝚙", "𝛒", "𝛠", "𝜌", "𝜚", "𝝆", "𝝔", "𝞀", "𝞎", "𝞺", "𝟈", "ｐ"],
    q: ["q", "ԛ", "գ", "զ", "𝐪", "𝑞", "𝒒", "𝓆", "𝓺", "𝔮", "𝕢", "𝖖", "𝗊", "𝗾", "𝘲", "𝙦", "𝚚"],
    r: ["r", "г", "ᴦ", "ⲅ", "ꭇ", "ꭈ", "ꮁ", "𝐫", "𝑟", "𝒓", "𝓇", "𝓻", "𝔯", "𝕣", "𝖗", "𝗋", "𝗿", "𝘳", "𝙧", "𝚛"],
    s: ["s", "ƽ", "ѕ", "ꜱ", "ꮪ", "𐑈", "𑣁", "𝐬", "𝑠", "𝒔", "𝓈", "𝓼", "𝔰", "𝕤", "𝖘", "𝗌", "𝘀", "𝘴", "𝙨", "𝚜", "ｓ"],
    t: ["t", "𝐭", "𝑡", "𝒕", "𝓉", "𝓽", "𝔱", "𝕥", "𝖙", "𝗍", "𝘁", "𝘵", "𝙩", "𝚝"],
    u: ["u", "ʋ", "υ", "ս", "ᴜ", "ꞟ", "ꭎ", "ꭒ", "𐓶", "𑣘", "𝐮", "𝑢", "𝒖", "𝓊", "𝓾", "𝔲", "𝕦", "𝖚", "𝗎", "𝘂", "𝘶", "𝙪", "𝚞", "𝛖", "𝜐", "𝝊", "𝞄", "𝞾"],
    v: ["v", "ν", "ѵ", "ט", "ᴠ", "ⅴ", "∨", "⋁", "ꮩ", "𑜆", "𑣀", "𝐯", "𝑣", "𝒗", "𝓋", "𝓿", "𝔳", "𝕧", "𝖛", "𝗏", "𝘃", "𝘷", "𝙫", "𝚟", "𝛎", "𝜈", "𝝂", "𝝼", "𝞶"],
    w: ["w", "ɯ", "ѡ", "ԝ", "ա", "ᴡ", "ꮃ", "𑜊", "𑜎", "𑜏", "𝐰", "𝑤", "𝒘", "𝓌", "𝔀", "𝔴", "𝕨", "𝖜", "𝗐", "𝘄", "𝘸", "𝙬", "𝚠"],
    x: ["x", "×", "х", "ᕁ", "ᕽ", "᙮", "ⅹ", "⤫", "⤬", "⨯", "𝐱", "𝑥", "𝒙", "𝓍", "𝔁", "𝔵", "𝕩", "𝖝", "𝗑", "𝘅", "𝘹", "𝙭", "𝚡", "ｘ"],
    y: ["y", "ɣ", "ʏ", "γ", "у", "ү", "ყ", "ᶌ", "ỿ", "ℽ", "ꭚ", "𑣜", "𝐲", "𝑦", "𝒚", "𝓎", "𝔂", "𝔶", "𝕪", "𝖞", "𝗒", "𝘆", "𝘺", "𝙮", "𝚢", "𝛄", "𝛾", "𝜸", "𝝲", "𝞬", "ｙ"],
    z: ["z", "ᴢ", "ꮓ", "𑣄", "𝐳", "𝑧", "𝒛", "𝓏", "𝔃", "𝔷", "𝕫", "𝖟", "𝗓", "𝘇", "𝘻", "𝙯", "𝚣"]
}

let keys = Object.keys(data)

keys.forEach(key => {
    data[key] = data[key].map(letter => ({ l: letter, used: key === letter }))
})


export default data;
